import { SupabaseClient } from '@supabase/supabase-js';
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import useSupabase from '../supabase';

export async function getGeolocationStatus(
  client: SupabaseClient<any, 'public', any>,
): Promise<any> {
  const { data, error } = await client.functions.invoke('geolocation');
  if (error) throw new Error(error.message);
  return data;
}

export function useGeolocation(): UseQueryResult<any, Error> {
  const client = useSupabase();
  const queryKey = ['geolocation_status'];

  const queryFn = async () => {
    const storedData = sessionStorage.getItem('geolocation_status');
    if (storedData) {
      return JSON.parse(storedData);
    } else {
      const result = await getGeolocationStatus(client);
      sessionStorage.setItem('geolocation_status', JSON.stringify(result));
      return result;
    }
  };

  return useQuery({ queryKey, queryFn });
}
